/* You can add global styles to this file, and also import other style files */

html {
    height: 100%;
}

body {
    background: linear-gradient(rgba(10, 10, 10, 0.5), rgba(10, 10, 10, 1)), url("./assets/REACH2.png") 0 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px;
    padding: 0px;
    font-family: 'smartSans-regular', sans-serif;
    overflow: inherit;
    background-attachment: fixed;
}

p {
    margin: 0;
}